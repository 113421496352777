import React from "react";
import './Faq.scss';
import Arrow2 from '../../Images/next.png'
import Review from '../../Images/Review.png'
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from '../../Images/Logo.png'
import closeMenu from '../../Images/closeMenu.png'

// images
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"


export default function Faq({ Blogs, FaqList }) {
    const navigate = useNavigate()
    const [faqlistFilter, setfaqlistFilter] = useState(FaqList);
    const [faqlistInputValue, setfaqlistInputValue] = useState("");
    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());
    const location = useLocation()
    useEffect(() => {
        // console.log(location.hash);
    }, []);

    return (


        <div className="Faq">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Fontana Chimney Sweep | Answer And Questions</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Fontana Chimney Sweep | Answer And Questions" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Fontana Chimney Sweep | Answer And Questions" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.fontanachimneysweep.org/faq" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the Fontana Chimney Sweep Faq Page for insightful articles on strategies, tips, and expert advice. Discover innovative ways to chimney, secure your home." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the Fontana Chimney Sweep Faq Page for insightful articles on strategies, tips, and expert advice. Discover innovative ways to chimney, secure your home." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Fontana Chimney Sweep | Answer And Questions" data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rain Cap Installation, Chimney Repair, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimney Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap, Faq Page" data-react-helmet="true" />
            </Helmet>
            <div className="FaqMainBanner">
                <h1>Chimney Sweep Questions</h1>
                <h2>For professional chimney repair services, contact Fontana Sweep. Our certified technicians are dedicated to providing reliable and high-quality repairs to keep your chimney safe and functional.</h2>
                <h5>Categories</h5>
                <div className="FaqMainBannerCategories">
                    <a href="/faq#chimney-cleaning">Chimney Cleaning</a>
                    <a href="/faq#chimney-inspection">Chimney Inspection</a>
                    <a href="/faq#chimney-maintenance">Chimney Maintenance</a>
                    <a href="/faq#chimney-repair">Chimney Repair</a>
                    <a href="/faq#Chimney-relining">Chimney Relining</a>
                    <a href="/faq#chimney-waterproofing">Chimney Waterproofing</a>
                    <a href="/faq#chimney-flashing-repair">Chimney Flashing Repair</a>
                </div>

            </div>
            {/* FaqMainList */}
            <div className="FaqMainList">
                {faqlistInputValue ?
                    <div className="FaqList">
                        <div className="FaqListHeaderResults">
                            <h6>Results</h6>
                            <h5 onClick={() => setfaqlistInputValue("")}>Restart Results</h5>
                        </div>
                        {faqlistFilter.filter(a =>
                            a.question.toLocaleLowerCase().includes(faqlistInputValue.toLocaleLowerCase()) ||
                            a.questionPath.toLocaleLowerCase().includes(faqlistInputValue.toLocaleLowerCase())
                        ).map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}

                        {faqlistFilter.length ?
                            <div className="FaqListNoResult">
                                <h1>We're sorry, but your search did not return any results.</h1>
                            </div>
                            : null}

                        <div className="FaqPageHeaderImagePromise">
                            <div className="FaqPageHeaderImagePromiseTop">
                                <div className="FaqPageHeaderImagePromiseTopLeft">
                                    <img src={Logo} alt="" />
                                    <h2>Fontana Chimney Sweep promise</h2>
                                </div>
                                <a href="/apply">More info</a>
                            </div>
                            <p>For professional chimney repair services, contact Fontana Sweep. Our certified technicians are dedicated to providing reliable and high-quality repairs to keep your chimney safe and functional.</p>
                        </div>
                    </div>
                    :
                    <div className="FaqList">
                        <h6 id="chimney-cleaning">Chimney Cleaning</h6>
                        {faqlistFilter.filter(a => a.category == "SBA  Loan").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <div className="FaqPageHeaderImagePromise">
                            <div className="FaqPageHeaderImagePromiseTop">
                                <div className="FaqPageHeaderImagePromiseTopLeft">
                                    <img src={Logo} alt="" />
                                    <h2>Fontana Chimney Sweep promise</h2>
                                </div>
                                <a href="/apply">More info</a>
                            </div>
                            <p>For professional chimney repair services, contact Fontana Sweep. Our certified technicians are dedicated to providing reliable and high-quality repairs to keep your chimney safe and functional.</p>
                        </div>
                        <h6 id="chimney-inspection">Chimney Inspection</h6>
                        {FaqList.filter(a => a.category == "Chimney Inspection").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="chimney-maintenance">Chimney Maintenance</h6>
                        {FaqList.filter(a => a.category == "Chimney Maintenance").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="chimney-repair">Chimney Repair</h6>
                        {FaqList.filter(a => a.category == "Chimney Repair").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="line-of-credit">Chimney Relining</h6>
                        {FaqList.filter(a => a.category == "Chimney Relining").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="chimney-waterproofing">Chimney Waterproofing</h6>
                        {FaqList.filter(a => a.category == "Chimney Inspection").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="Chcmney-flashing-repair">Chimney Flashing Repair</h6>
                        {FaqList.filter(a => a.category == "Chimney Flashing Repair").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                    </div>
                }
                <aside className="FaqSearch">
                    <h1>Categories</h1>
                    <div className="FaqMainBannerCategories">
                        <a style={location.hash == "#chimney-cleaning" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#chimney-cleaning">Chimney Cleaning</a>
                        <a style={location.hash == "#chimney-inspection" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#chimney-inspection">Chimney Inspection</a>
                        <a style={location.hash == "#chimney-maintenance" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#chimney-maintenance">Chimney Maintenance</a>
                        <a style={location.hash == "#chimney-repair" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#chimney-repair">Chimney Repair</a>
                        <a style={location.hash == "#Chimney-relining" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#Chimney-relining">Chimney Relining</a>
                        <a style={location.hash == "#chimney-waterproofing" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#chimney-waterproofing">Chimney Waterproofing</a>
                        <a style={location.hash == "#Chcmney-flashing-repair" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#chimney-flashing-repair">Chimney Flashing Repair</a>
                    </div>
                    <h1>Search for question</h1>
                    <div className="FaqSearchInput">
                        <input
                            value={faqlistInputValue || ''}
                            onFocus={() => {
                                window.screen.width >= 850 ? window.scrollTo(0, 420) : window.scrollTo(0, 570)
                            }}
                            onChange={(e) => {
                                setfaqlistInputValue(e.target.value)
                                window.screen.width >= 850 ? window.scrollTo(0, 420) : window.scrollTo(0, 570)

                            }} type="text" placeholder="Search..." />
                        {faqlistInputValue ?
                            <img onClick={() => setfaqlistInputValue("")} src={closeMenu} alt="" />
                            :
                            null}
                    </div>
                </aside>
            </div>

            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>
                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>

                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>

                        </a>
                    )}
                </div>
            </div>
            <div className="FaqApply">
                <h1>For professional chimney repair services, contact Fontana Sweep. Our certified technicians are dedicated to providing reliable and high-quality repairs to keep your chimney safe and functional.</h1>
                <div className="FaqApplyBox">
                    <img src={Review} />
                    <h2> Submit your application within minutes <br /> No commitment required</h2>
                    <a href="/apply" >Apply Now</a>
                </div>

            </div>

            <NavigatorPath />

        </div >
    )
}

